<template>
    <div class="basetemplate-evelle2011-2cols-65-35 grid grid-cols-[calc(65%_-_var(--gap-size)/2)_calc(35%_-_var(--gap-size)_/_2)] md:grid-cols-1 gap-[var(--gap-size)]">
        <slot></slot>
    </div>
</template>

<style>
:root {
  --gap-size: 2rem;
}
</style>